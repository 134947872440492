<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item :label="$t('safety.monitorAddress')" prop="link">
      <a-input
        v-model="form.link"
        :placeholder="$t('safety.requiredMonitorAddress')"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component()
export default class AddMonitor extends Vue {
  @Prop({ type: String, default: '' }) link;

  mounted() {
    this.form.link = this.link;
  }
  form = {
    link: '',
  };
  rules = {
    link: [
      {
        required: true,
        message: this.$t('safety.requiredMonitorAddress'),
      },
    ],
  };

  async getValue() {
    await this.$refs.form.validate();
    return this.form.link;
  }
}
</script>
