<template>
  <div :class="$style.detail">
    <DetailHeader :headerData="headerData" />
    <div>
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="device-status" :tab="$t('safety.deviceStatus')">
          <div v-if="activeKey === 'device-status'" :class="$style.listWrap">
            <StatusInfo
              :textObj="baseInfoText"
              :label="$t('detail.baseInfo')"
              :showUnit="true"
              :iconList="baseIcons"
            />
            <StatusInfo
              :textObj="statusText"
              :label="$t('detail.runningStatus')"
            />
            <StatusInfo
              :textObj="upDeviceText"
              :label="$t('detail.onboardInfo')"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="running-record" :tab="$t('detail.runningRecord')">
          <div v-if="activeKey === 'running-record'" :class="$style.listWrap">
            <RunningRecord :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="up-record" tab="上下机记录">
          <div v-if="activeKey === 'up-record'" :class="$style.listWrap">
            <UpRecord :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="device-warning">
          <span slot="tab">
            <a-badge
              style="width: 40px;"
              :count="alarmCount"
              :number-style="{
                backgroundColor: 'var(--primary)',
                boxShadow: '0 0 0 1px var(--primary) inset',
                top: '-5px',
              }"
            >
              {{ $t('safety.alarm') }}
            </a-badge>
          </span>
          <div v-if="activeKey === 'device-warning'" :class="$style.listWrap">
            <DeviceWarning :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="device-monitor" :tab="$t('safety.monitor')">
          <div v-if="activeKey === 'device-monitor'" :class="$style.listWrap">
            <Monitor :deviceId="deviceId" :pageType="pageType" />
          </div>
        </a-tab-pane>
        <div slot="tabBarExtraContent">
          <div v-if="activeKey === 'device-status'">
            {{ $t('detail.lastUpdateTime') }}：{{ lastUpdateTime }}
          </div>
        </div>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import DetailHeader from '@/views/safety-device/component/detail-header';
import DeviceWarning from '@/views/safety-device/component/device-warning';
import StatusInfo from '@/views/safety-device/component/status-info';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import Monitor from '@/views/safety-device/component/monitor';
import { deviceSafetyInfo } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import RunningRecord from './running-record';
import UpRecord from './up-record';

@Component({
  components: {
    DetailHeader,
    DeviceWarning,
    StatusInfo,
    ScreenSelect,
    RunningRecord,
    UpRecord,
    Monitor,
  },
})
export default class TowerDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) detailData;
  @Prop({ type: String, default: '' }) pageType;

  headerData = {};
  mounted() {
    this.initHeadData();
  }
  alarmCount = 0;
  deviceId = '';
  initHeadData() {
    const data = this.detailData;
    this.headerData = {
      deviceName: data.deviceName,
      deviceCode: data.deviceCode,
      bindTime: data.bindTime,
      projectName: data.projectName,
      remark: data.remark,
    };
    this.alarmCount =
      data.alarmCount !== 0 && data.alarmCount !== -1 ? data.alarmCount : 0;
    this.deviceId = data.pkId;
    this.getDeviceInfoList();
  }
  activeKey = 'device-status';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  changeMinusOne(val) {
    return val !== -1 ? val ?? '-' : '-';
  }
  changeStatus(val) {
    return ['-', '正常', '预警', '报警'][val] ?? '-';
  }
  lastUpdateTime = '';
  async getDeviceInfoList() {
    try {
      const res =
        (await deviceSafetyInfo(this.deviceId, this.deviceGroup)) ?? {};
      for (const key in this.baseInfoText) {
        if (key === 'liftingWeight') {
          this.baseInfoText[key].value = this.changeMinusOne(res.liftingWeight);
          this.baseInfoText[key].status = this.changeMinusOne(
            res.liftingWeightStatus,
          );
        } else if (key === 'height') {
          this.baseInfoText[key].value = this.changeMinusOne(res.height);
          this.baseInfoText[key].status = this.changeMinusOne(
            res.heightLimitStatus,
          );
        } else if (key === 'windSpeed') {
          this.baseInfoText[key].value = this.changeMinusOne(res.windSpeed);
          this.baseInfoText[key].status = this.changeMinusOne(
            res.windSpeedStatus,
          );
        } else if (key === 'camber') {
          this.baseInfoText[key].value = this.changeMinusOne(res.camber);
          this.baseInfoText[key].status = this.changeMinusOne(
            res.inclinationStatus,
          );
        } else {
          this.baseInfoText[key].value = this.changeMinusOne(res[key]);
        }
      }
      for (const key in this.statusText) {
        this.statusText[key].value = this.changeStatus(res[key]);
      }

      for (const key in this.upDeviceText) {
        this.upDeviceText[key].value = this.changeMinusOne(res[key]);
      }
      // this.upDeviceText.driverName.value = res.driverName
      //   ? res.driverName
      //   : '-';
      // this.upDeviceText.driverIdentityNumber.value = res.driverIdentityNumber
      //   ? res.driverIdentityNumber
      //   : '-';
      // this.upDeviceText.faceTime.value = this.formateTime(res.faceTime);
      // this.upDeviceText.faceStatus.value =
      //   res.faceStatus !== -1 ? ['未识别', '已识别'][res.faceStatus] : '-';
      this.lastUpdateTime = this.formateTime(res.lastUpdateTime);
    } catch {
      return false;
    }
  }
  baseIcons = ['liftingWeight', 'height', 'windSpeed', 'camber'];
  baseInfoText = {
    liftingWeight: {
      text: this.$t('detail.liftingWeight'),
      unit: this.$t('unit.ton'),
      value: '',
      status: '',
    },
    margin: {
      text: this.$t('detail.margin'),
      unit: this.$t('unit.meter'),
      value: '',
    },
    height: {
      text: '吊钩高度',
      unit: this.$t('unit.meter'),
      value: '',
      status: '',
    },
    rotationAngle: {
      text: this.$t('detail.rotationAngle'),
      unit: this.$t('safety.limit'),
      value: '',
    },
    ratedLiftingWeight: {
      text: '最大吊重',
      unit: this.$t('unit.ton'),
      value: '',
    },
    torquePercentage: {
      text: '力矩百分比',
      unit: '%',
      value: '',
    },
    windSpeed: {
      text: this.$t('detail.windspeed'),
      unit: this.$t('unit.meter') + '/' + this.$t('unit.second'),
      value: '',
      status: '',
    },
    camber: {
      text: '倾角',
      unit: this.$t('safety.limit'),
      value: '',
      status: '',
    },
    moment: {
      text: '起重力矩',
      unit: this.$t('N·m'),
      value: '',
    },
    speed: {
      text: '运行速度',
      unit: this.$t('m/s'),
      value: '',
    },
    loadPercentage: {
      text: '载荷百分比',
      unit: this.$t('%'),
      value: '',
    },
    workStatus: {
      text: '工作状态',
      value: '',
      format: text => ['-', '空闲', '正常'][text] ?? '-',
    },
    angleX: {
      text: '塔机X倾斜角度',
      unit: '°',
      value: '',
    },
    angleY: {
      text: '塔机Y倾斜角度',
      unit: '°',
      value: '',
    },
  };
  statusText = {
    amplitudeOutwardLimitStatus: {
      text: this.$t('detail.amplitudeOutwardLimitStatus'),
      value: '',
    },
    amplitudeInwardLimitStatus: {
      text: this.$t('detail.amplitudeInwardLimitStatus'),
      value: '',
    },
    regionalLimitStatus: {
      text: this.$t('detail.regionalLimitStatus'),
      value: '',
    },
    groupTowerLimitStatus: {
      text: this.$t('detail.groupTowerLimitStatus'),
      value: '',
    },
  };
  upDeviceText = {
    driverName: {
      text: this.$t('detail.driverName'),
      value: '',
    },
    driverIdentityNumber: {
      text: this.$t('detail.driverIdentityNumber'),
      value: '',
    },
    faceTime: {
      text: this.$t('detail.onBoardTime'),
      value: '',
      format: val => this.formateTime(val),
    },
    faceStatus: {
      text: this.$t('detail.faceStatus'),
      value: '',
      format: val => ['未识别', '已识别'][val] ?? '-',
    },
    driverLicenseStatus: {
      text: '持证状态',
      value: '',
      format: text => ['未持证', '已持证'][text] ?? '-',
    },
  };
}
</script>

<style lang="less" module>
@import '../component/safety.less';
</style>
