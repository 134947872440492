<template>
  <div style="min-height: 400px">
    <ScreenSelect
      @changeTimeRange="selectTime"
      :typeOptions="typeOptions"
      @changeType="changeType"
      :showType="showType"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="tableScroll"
      @change="handlePagination"
      :transformCellText="({ text }) => (text === -1 ? '-' : text || '-')"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { deviceAlarmList } from '@/services/device-manage/device';
import { deviceTypeEnum } from '@/enum/device';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import { deviceTypeList } from '@/services/device-manage/device-detail';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceWarning extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;
  @Prop({ type: Array, default: () => [] }) otherColumn;
  @Prop({ type: Boolean, default: true }) showType;

  get tableScroll() {
    if (this.otherColumn.length > 0) {
      return { x: 1000 };
    } else {
      return {};
    }
  }
  get columns() {
    const cc = [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '报警时间',
        dataIndex: 'alarmTime',
        width: 200,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
      {
        align: 'left',
        title: '事件类型',
        dataIndex: 'subType',
        width: 130,
        ellipsis: true,
        customRender: txt => (txt ? this.changeSubType(txt) : '-'),
      },
      {
        align: 'left',
        title: this.$t('safety.type'),
        dataIndex: 'type',
        width: 130,
        ellipsis: true,
        customRender: txt =>
          ({
            WARN: '预警',
            ERROR: '报警',
          }[txt] ?? '-'),
      },
      {
        align: 'left',
        title: '报警数据',
        dataIndex: 'reason',
        ellipsis: true,
        customRender: (txt, record) =>
          txt ? this.changeReason(txt, record) : '-',
      },
    ];
    if (this.otherColumn.length > 0) {
      cc.splice(2, 0, this.otherColumn[0]);
    }
    return cc;
  }
  changeSubType(val) {
    const typeList = this.typeOptions;
    const { label } = typeList.find(v => v.value === val) ?? { label: '-' };
    return label;
  }
  changeReason(val, row) {
    if (this.pageType === 'WM') {
      return this.changeStr(row, 'WM');
    } else if (this.pageType === 'EM') {
      return this.changeStr(row, 'EM');
    } else {
      return val;
    }
  }
  changeStr(row, flag) {
    const timeDay = row.alarmtime
      ? this.dayjs(row.alarmtime).format('YYYY-MM-DD')
      : '';
    const m = timeDay ? timeDay.split('-') : '';
    const timeMonth = m ? `${m[0]}-${m[1]}-01` : '';
    const obj = {
      WATER_METER_TO_DAY: '日用水量',
      WATER_METER_CURRENT_MONTH: '月用水量',
      ELE_METER_TO_DAY: '日用电量',
      ELE_METER_CURRENT_MONTH: '月用电量',
    };
    const unit = {
      WM: 'm³',
      EM: 'kWh',
    };
    const dayOrMonth =
      ['WATER_METER_TO_DAY', 'ELE_METER_TO_DAY'].indexOf(row.subType) > -1;
    const t = dayOrMonth ? timeDay : timeMonth;
    const dm = dayOrMonth ? '日' : '月';
    return `${t}${obj[row.subType]}${row.alarmValue}${unit[flag]}，每${dm}限制${
      row.threshold
    }${unit[flag]}`;
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  typeOptions = [];
  mounted() {
    this.getTypeList();
    this.getTableList();
  }
  async getTypeList() {
    try {
      this.typeOptions = await deviceTypeList(this.deviceGroup);
    } catch {
      return false;
    }
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  selectType = {
    type: '',
    value: '',
  };
  changeType(v) {
    if (v && v.value !== 'all') {
      this.selectType = v;
    } else {
      this.selectType = {
        type: '',
        value: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    const params = {
      deviceId: this.deviceId,
      query: {
        current: this.pagination.current,
        size: this.pagination.pageSize,
      },
      deviceGroup: this.deviceGroup,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    if (this.selectType.value) {
      params.type = this.selectType.type;
      params.subType = this.selectType.value;
    }
    this.loading = true;
    try {
      const { records, current, size, total } = await deviceAlarmList(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style></style>
