<template>
  <div>
    <ScreenSelect :showType="false" @changeTimeRange="selectTime" />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: 1200 }"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceRunningRecord } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import ScreenSelect from '@/views/safety-device/component/screen-select';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class RunningRecord extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('detail.unloadingTime'),
        width: 250,
        dataIndex: 'startTime',
      },
      {
        align: 'left',
        title: this.$t('detail.craneHeight'),
        dataIndex: 'startHeight',
      },
      {
        align: 'left',
        title: this.$t('detail.craneMargin'),
        dataIndex: 'startMargin',
      },
      {
        align: 'left',
        title: this.$t('detail.craneAngle'),
        dataIndex: 'startRotationAngle',
      },
      {
        align: 'left',
        title: this.$t('detail.maxTorquePercentage'),
        dataIndex: 'maxTorquePercentage',
      },
      {
        align: 'left',
        title: this.$t('detail.liftingWeight'),
        dataIndex: 'liftingWeight',
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  tableData = [];
  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
    }
  }
  mounted() {
    this.getTableList();
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  formateTimeRange(t) {
    let endTime;
    if (t.endTime - t.startTime > 24 * 3600 * 1000) {
      endTime = this.dayjs(t.endTime).format('YYYY/MM/DD HH:mm:ss');
    } else {
      endTime = this.dayjs(t.endTime).format('HH:mm:ss');
    }
    const startTime = this.dayjs(t.startTime).format('YYYY/MM/DD HH:mm:ss');
    return `${startTime}-${endTime}`;
  }
  loading = false;
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await deviceRunningRecord(
        params,
        this.deviceGroup,
      );
      this.tableData = records.map(v => ({
        startTime: this.formateTimeRange(v),
        startHeight: `${v.startHeight}米/${v.endHeight}米`,
        startMargin: `${v.startMargin}米/${v.endMargin}米`,
        startRotationAngle: `${v.startRotationAngle}度/${v.endRotationAngle}度`,
        maxTorquePercentage: v.maxTorquePercentage + '%',
        liftingWeight: v.liftingWeight + '吨',
      }));
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>
