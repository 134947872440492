import request from '../request';

const serviceName = '/smart-site/device';

/** @name 塔吊监控地址录入 */
export function updateMonitor(data) {
  return request(`${serviceName}/tcsf/monitor`, {
    method: 'PUT',
    body: data,
  });
}

/** @name 获取塔吊监控地址 */
export function fetchMonitorDetail(id) {
  return request(`${serviceName}/tcsf/monitor/${id}`, {
    method: 'GET',
  });
}
