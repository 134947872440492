<template>
  <div>
    <a-button type="primary" @click="handleClick" :class="$style.mb20">
      {{ $t('safety.monitor') }}
    </a-button>
    <EmptyContent
      v-if="!link && loaded"
      :label="$t('safety.setMonitorAddress')"
    />
    <VideoPlayer
      v-if="link && loaded"
      :url="link"
      :size="{ width: '100%', height: '450px' }"
    />
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import { createFormModal } from '@triascloud/x-components';
import AddMonitor from './add-monitor.vue';
import VideoPlayer from '@/components/video-player/index.vue';
import {
  fetchMonitorDetail,
  updateMonitor,
} from '@/services/device-manage/tcsf';

@Component({
  components: {
    EmptyContent,
    VideoPlayer,
  },
})
export default class Monitor extends Vue {
  @Prop({ type: String, default: '' }) deviceId;
  async handleClick() {
    try {
      const result = await createFormModal(
        () => <AddMonitor link={this.link} />,
        {
          width: 500,
          title: this.$t('safety.monitorPlayAddress'),
        },
      );
      await updateMonitor({
        deviceId: this.deviceId,
        url: result,
      });
      this.link = '';
      setTimeout(() => {
        this.link = result;
      }, 20);
    } catch (error) {
      if (error.message !== 'user cancel') {
        this.$message.error(error.message);
      }
    }
  }

  mounted() {
    this.fetchMonitor();
  }

  link = '';
  loaded = false;
  async fetchMonitor() {
    try {
      const result = await fetchMonitorDetail(this.deviceId);
      this.link = result || '';
    } catch (error) {
      this.$message.error(error.message);
    } finally {
      this.loaded = true;
    }
  }
}
</script>
<style lang="less" module>
.mb20 {
  margin-bottom: 20px;
}
</style>
