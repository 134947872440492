<template>
  <a-form-model ref="formRef" :model="form" :rules="rules">
    <a-spin :spinning="spinning">
      <div class="grid grid-cols-2 gap-20">
        <a-form-model-item label="项目" prop="projectId">
          <AsyncSelect
            v-model="form.projectId"
            :async-options="projectOptions"
            placeholder="请选择项目"
            @change="projectChange"
          ></AsyncSelect>
        </a-form-model-item>

        <a-form-model-item label="设备" prop="deviceId">
          <AsyncSelect
            ref="deviceSelectRef"
            v-model="form.deviceId"
            :async-options="deviceOptions"
            :allowClear="false"
            placeholder="请选择设备"
            :auto-fecth="false"
            mode="multiple"
            @select="handleSelect"
            @deselect="handleDeSelect"
          ></AsyncSelect>
        </a-form-model-item>
      </div>

      <div class="flex items-center justify-between" style="margin-top: 20px;">
        <span>塔吊平面图</span>
        <a-form-model-item prop="planUrl">
          <OssUpload
            accept="image/*"
            v-model="form.planUrl"
            :maxCount="1"
            :showUploadList="false"
            @success="handleUploadSuccess"
          >
            <a-button type="primary">
              上传平面图
            </a-button>
          </OssUpload>
        </a-form-model-item>
      </div>

      <div
        :class="$style.canvasWrap"
        class="flex items-center justify-center"
        id="canvas-wrap"
      >
        <PlanViewCanvas
          ref="canvasRef"
          :background="canvasBgImg"
        ></PlanViewCanvas>
      </div>
    </a-spin>
  </a-form-model>
</template>

<script>
import AsyncSelect from '@/components/async-select';
import { Component } from 'vue-property-decorator';
import OssUpload from '../component/upload.vue';
import PlanViewCanvas from './components/tower-canvas.vue';
import PlanViewConfigMixin from '../mixin/plan-view-config';
import { mixins } from 'vue-class-component';

@Component({ components: { AsyncSelect, OssUpload, PlanViewCanvas } })
export default class PlanViewConfig extends mixins(PlanViewConfigMixin) {}
</script>

<style lang="less" module>
.canvasWrap {
  border: solid 1px var(--border);
  margin-top: 10px;
  aspect-ratio: 16/9;
  overflow: hidden;
}
</style>
