<template>
  <div :class="$style.baseInfo">
    <div v-if="label" :class="$style.text">
      <span style="margin-left: 5px;">
        {{ label }}
      </span>
    </div>
    <div class="grid grid-cols-3" :class="$style.sectionWrap">
      <div
        class="flex flex-col items-center justify-center"
        :class="$style.itemWrap"
        v-for="(item, key) in textObj"
        :key="key"
      >
        <div class="">
          <span>{{ item.text }}</span>
          <span v-if="iconList.indexOf(key) !== -1" style="margin-left: 5px;">
            <a-icon
              v-if="item.status === 0 && item.status !== '-'"
              :class="$style.normal"
              type="check-circle"
              theme="filled"
            />
            <a-icon
              v-if="item.status !== 0 && item.status !== '-'"
              :class="item.status === 1 ? $style.warning : $style.alarm"
              type="exclamation-circle"
              theme="filled"
            />
          </span>
        </div>
        <div class="">
          <span>{{
            item.format ? item.format(item.value) : item.value ?? '-'
          }}</span>
          <span v-if="showUnit">{{ item.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component()
export default class StatusInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) textObj;
  @Prop({ type: String, default: '' }) label;
  @Prop({ type: Boolean, default: false }) showUnit;
  @Prop({ type: Array, default: () => [] }) iconList;

  @Watch('textObj', { deep: true })
  changeData(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.textObj = newVal;
    }
  }
}
</script>

<style lang="less" module>
.info {
  text-align: center;
  white-space: nowrap;
  .label {
    color: var(--font-info);
    margin-bottom: 5px;
  }
}
.baseInfo {
  .text {
    font-weight: 600;
    border-left: 4px solid var(--primary);
  }
}
.sectionWrap {
  padding: 20px 0;
}
.itemWrap {
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid var(--border);
}

.normal {
  color: var(--success);
}
.warning {
  color: var(--warning);
}
.alarm {
  color: var(--delete);
}
</style>
